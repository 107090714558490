import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import ServicesHome from "../Services/ServicesHome_5";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="md:w-[98%] mx-auto my-12 text-center bg-[#FEFDFE]">
        <h2 className="text-center py-4">{rpdata?.labels?.general?.titleServices}</h2>
        <ButtonContent btnStyle="three" btnName="view all services" btnLink={'services'} />
        <div className="flex flex-wrap items-center justify-center relative">
          {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text.substring(0, 160) + "..."}
              />
            );
          })}

          <articule className="w-[200px] absolute bottom-[-100px] z-20 md:w-[250px] md:bottom-[-130px] lg:w-[300px] lg:bottom-[-100px] lg:left-0">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/House.gif?alt=media&token=9c93b148-0475-4244-b2f1-c069920a15c8"
              className="w-full rounded-sm"
              alt="construction gif"
            />
          </articule>

        </div>
      </div>
    </>
  );
}

export default ContentServicesHome;
